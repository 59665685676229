import React, { useEffect, useState } from 'react';
import { withPrefix } from 'gatsby-link';
import Loadable from '@loadable/component';
import { _dontBeAverageChartOptions, _offerChartSeries } from './index';
import { set } from 'date-fns';

export default function ({
  inline,
  article,
  bottomText,
  noText,
  waitForTrap,
  showTrap
}) {
  const [chartSeries, setChartSeries] = useState(
    waitForTrap ? [] : _offerChartSeries // Start with no series if waiting for trap
  );
  const [trapChartOptions, setTrapChartOptions] = useState({
    ..._dontBeAverageChartOptions,
    animations: {
      enabled: true,
      easing: 'easeout',
      speed: 800
    }
  });

  useEffect(() => {
    if (showTrap) {
      // Delay setting the series to create the animation effect
      setTimeout(() => {
        setChartSeries(_offerChartSeries);
      }, 200); // Adjust this delay if needed
    }
  }, [showTrap]);

  return (
    <div className="article article-1">
      <div className="article-anchor" id="article1"></div>

      <div className="main">
        {!article ? <h2>The 1,000-kilowatt trap</h2> : null}

        <div className="text-block">
          {trapChartOptions ? (
            <div className="chart">
              <div className="chart-container">
                <LoadableChart
                  key="trapChart"
                  options={trapChartOptions}
                  series={chartSeries}
                  type="line"
                  width={inline ? '500px' : '700px'}
                  height="400px"
                  className="hide-mobile"
                />
                <LoadableChart
                  key="trapChart"
                  options={trapChartOptions}
                  series={chartSeries}
                  type="line"
                  width="320px"
                  height="350px"
                  className="mobile-only"
                />
                <div className="hand chart-text">
                  <img
                    src={withPrefix('/images/misc/trap-overlay.png?v=3')}
                    alt="The 1,000 Kilowatt Trap"
                  />
                  <span className="top-text">
                    Example Advertised Price at 14.5¢ - if you use exactly 1,000
                    kWh every month
                  </span>
                  <span className="state-average">Advertised price</span>
                  <span className="bottom-text">
                    Use any more or less and <u>your price goes up</u>
                  </span>
                </div>
              </div>
            </div>
          ) : null}

          {!noText && (
            <>
              {' '}
              <h4>
                Want to know a secret? The plans with the lowest advertised
                rates are not always the best overall deals.
              </h4>
              <p>
                That’s because they get to those really low rates through
                pricing adjustments, usually in the form of a bill credit at
                exactly 1,000 kilowatts.
              </p>
              <p>
                That means that ‘really low price’ you saw only applies if you
                use exactly 1,000 kilowatts. Use 1 kilowatt less and your rate
                can sometimes double.
              </p>
              <p>
                And since no one uses exactly 1,000 kilowatts every month,
                everyone ends up paying more than the advertised price. That
                said, plans with bill credits can still be a good deal in some
                circumstances, but only if your usage profile falls within the
                plan’s sweet spot, that area just past, but not too far past,
                the credit threshold.
              </p>
              <p className="sc-desktop-only">
                You can avoid the 1,000-kilowatt trap by using SmarterChoice’s
                “True Price” column to find YOUR best deal.
              </p>
              <p className="sc-mobile-only">
                You can avoid the 1,000-kilowatt trap by looking for the plan
                with the Best Year Round Value here at SmarterChoice.
              </p>
            </>
          )}
          {bottomText ? bottomText : null}
        </div>
      </div>
    </div>
  );
}
const LoadableChart = Loadable(() => import('react-apexcharts'));
